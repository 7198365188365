<template>
  <div class="portal">
    <portal-header class="portal__header" />

    <v-container fluid class="merchant-portal__container">
      <custom-alert
        v-if="showPaymentAlert"
        class="mp-alert"
        type="error">
        You will not be able to transact until an account admin connects a bank account in
        <span class="font-weight-bold">Admin > Payment Settings.</span>
      </custom-alert>

      <custom-alert
        v-if="showLoanAlert"
        type="warning"
        class="mp-alert">
        {{ loanAlertMsg }}
      </custom-alert>

      <admin-verification-modal @close="closeWelcomeDialog" />

      <payment-settings-update-modal v-if="welcomeDialog && showPaymentSettingsUpdateModal" />

      <router-view />
    </v-container>

    <merchant-portal-footer class="portal__footer" />

    <APRCapCompliancePopup
      v-if="showAprCapCompliancePopup && merchantAppUuid"
      :merchant-app-uuid="merchantAppUuid"
      :disable-button="patchingConsent"
      @acknowledge="acknowledgeAprCap" />
  </div>
</template>

<script lang="ts">
import MerchantPortalFooter from '@/components/Merchant/Portal/Footer.vue';
import PortalHeader from '@/components/Merchant/Portal/Header/index.vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import LocalStorageConstants from '@/constants/LocalStorageConstants';
import AdminVerificationModal from '@/components/Merchant/Portal/AdminVerificationModal.vue';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import PaymentSettingsUpdateModal from '@/components/PaymentProviders/PaymentSettingsUpdateModal.vue';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import { patchAvailableConsents } from '@/utils/Consents';
import { getAxiosError } from '@/api/utils';
import FullStoryLogging from '@/logging/FullStory';
import MerchantHumanReadableApplicationId from '@/mixins/Merchant/HumanReadableApplicationId';
import APRCapCompliancePopup from '@/components/Dialogs/APRCapCompliancePopup.vue';
import { updateSession } from '@/api/account';
import { userLogout } from '@/api/index';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MerchantPortal',
  components: {
    APRCapCompliancePopup,
    MerchantPortalFooter,
    PortalHeader,
    CustomAlert,
    AdminVerificationModal,
    PaymentSettingsUpdateModal,
  },
  mixins: [MerchantHumanReadableApplicationId],
  beforeRouteEnter(to, _, next) {
    const locationUuid = to.query.location;
    if (typeof locationUuid === 'string' && locationUuid.length > 0) {
      sessionStorage.setItem(LocalStorageConstants.LOCATION_UUID, locationUuid);
    }
    next();
  },
  data() {
    return {
      welcomeDialog: false,
      showLoanAlert: false,
      showPaymentSettingsUpdateModal: false,
      fetchingPaymentProviders: false,
      patchingConsent: false,
      loanAlertMsg: 'Your Rate Sheet has not been activated. '
        + 'An admin on your account must activate loan products '
        + 'in the Rate Sheet before you can send an application.',
    };
  },
  computed: {
    merchantData() {
      return this.$store.getters['MerchantPortal/getMerchantData'];
    },
    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },
    showPaymentAlert(): boolean {
      if (!this.merchantData) return false;
      return this.$route.name !== 'merchant-activity-center'
      && this.paymentProviders?.repay?.can_transact === false
      && this.paymentProviders?.moov?.can_transact === false;
    },
    hasRepayAccount(): boolean {
      return this.paymentProviders?.repay?.can_transact
        && this.merchantData?.merchant?.has_two_or_more_products_active;
    },
    showAprCapCompliancePopup() {
      return this.$store.getters['MerchantPortal/getAprCapComplianceDialog'];
    },
    merchantAppUuid() {
      return this.$store.getters['MerchantPortal/getMerchantApplicationUuid'];
    },
  },
  watch: {
    merchantData: {
      async handler(val: any) {
        if (val) {
          if (val.merchant.has_two_or_more_products_active) {
            this.showLoanAlert = false;
          } else {
            this.showLoanAlert = true;
          }
          await this.showBankAccountStatusMessages();
        } else {
          await this.fetchMerchantData();
        }
      },
      immediate: true,
    },
  },
  created() {
    localStorage.removeItem(LocalStorageConstants.CURRENTLY_ONBOARDING);

    const user = this.$store.getters['Auth/getUser'];

    FullStoryLogging.identify(
      this.$store.getters['Auth/getUserId'],
      {
        email: this.$store.getters['Auth/getUser'].email,
        displayName: user.first_name ? `${user.first_name} ${user.last_name}` : undefined,
        merchantUuid: this.$store.getters['Auth/getMerchantUuid'],
        merchantName: this.$store.getters['Auth/getMerchant'].name,
        merchantId: this.merchantHumanReadableIdLabel || undefined,
      },
    );
  },
  unmounted() {
    this.$store.dispatch('resetState');
  },
  methods: {
    async fetchPaymentProviders() {
      if (!Object.keys(this.paymentProviders).length && !this.fetchingPaymentProviders) {
        this.fetchingPaymentProviders = true;
        await this.$store.dispatch('MerchantPortal/getPaymentProviders');
        this.fetchingPaymentProviders = false;
      }
    },

    async fetchMerchantData(): Promise<void> {
      await this.$store.dispatch('MerchantPortal/dispatchGetMerchantData');

      const isIC1Sponsored = this.$store.getters['MerchantPortal/isIC1Sponsored'];
      const mpAccess = this.$store.getters['MerchantPortal/getMerchantPortalAccess'];
      if (isIC1Sponsored && !mpAccess) {
        if (this.$store.getters['Auth/getIsLoggedIn']) {
          await updateSession({ logged_out_at: new Date() });
          await userLogout(false);
        }
        this.$router.push('/error-page');
      }

      await this.fetchPaymentProviders();

      const user = this.$store.getters['Auth/getUser'];

      FullStoryLogging.setUserVars({
        displayName: user.first_name ? `${user.first_name} ${user.last_name}` : undefined,
        email: this.$store.getters['Auth/getUser'].email,
        merchantName: this.$store.getters['Auth/getMerchant'].name,
        merchantId: this.merchantHumanReadableIdLabel,
      });
    },
    closeWelcomeDialog(val: boolean) {
      this.welcomeDialog = val;
    },
    async showBankAccountStatusMessages() {
      try {
        await this.fetchPaymentProviders();
      } catch (error: unknown) {
        const axiosError = getAxiosError(error);
        if (axiosError) {
          this.showPaymentSettingsUpdateModal = axiosError.response?.status === 404
          && !this.hasRepayAccount;
        }
      }
    },
    async acknowledgeAprCap() {
      this.patchingConsent = true;
      const userId = this.$store.getters['Auth/getUserId'];
      const status = await patchAvailableConsents(
        [ConsentTypesEnum.APR_CAP_COMPLIANCE],
        this.merchantAppUuid,
        ConsentEntityTypes.MERCHANT,
        userId,
      );

      if (status === 201) {
        this.$store.dispatch('MerchantPortal/setAprCapComplianceDialog', false);
      } else if (status > 299) {
        this.$store.dispatch('Ui/addGlobalAlert', {
          text: 'Something went wrong, please refresh your browser',
          type: GlobalAlertTypes.ERROR,
          timed: false,
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import '@/assets/scss/mixins/media_queries';
@include portal-page-layout;

:deep(.v-alert) {
  margin: 0 !important;
}

.mp-alert {
  margin-bottom: 2rem;
}

.portal {
  background-color: var(--grayscale-color-4);
}

@include tablet {
  .portal {
    display: block;
    min-width: auto;

    &__container, &__page {
      width: 100%;
      max-width: 100%;
    }

    &__container {
      padding: 0 1rem;
    }
  }
}

@include mobile {
  .mp-alert {
    margin-bottom: 2rem;
  }

  .v-row {
    margin: 0;
  }

  .portal {
    display: block;
    min-width: auto;

    &__container {
      padding: 0;
    }
  }
}

</style>
